<template>
  <el-container class="h-100">
    <meta name="viewport" content="width=device-width,user-scalable=no,initial-scale=1.0">
    <div class="h-100 w-500">
      <header class="h5_header">
        <h1>{{ h5Data.doc_header.doc_title }}</h1>
        <p v-if="h5Data.doc_header.doc_subtitle">{{ h5Data.doc_header.doc_subtitle }}</p>
      </header>
      <div class="h5_nav mar-t-15">
        <a @click="handleClick('gs')" class="active">综述</a>
        <a @click="handleClick('jczl')">舆情态势</a>
        <a @click="handleClick('zdxxzy')">舆情焦点</a>
        <a @click="handleClick('xxylb')">信息一览表</a>
      </div>
      <div v-if="activeIndex == 'gs'" class="gs_cont">
        <div class="h5_card">
          <h3>本次简报收集了：</h3>
          <p>{{ h5Data.diagram_info1_header }}</p>
        </div>
        <div class="h5_card mar-t-10">
          <p>{{ h5Data.diagram_info2_1_header }}</p>
          <!-- 信息声量走势 -->
          <div class="ov mar-t-10 h5_card">
            <h2 class="tit"><span>信息声量走势</span></h2>
            <div class="map" id="voicetrend"></div>
          </div>
          <!--@end 信息声量走势 -->
          <div class="ov mar-t-10">
            <h3>高峰日焦点信息</h3>
            <ul class="zdxxzy_cont">
              <li v-for="(row, index) in h5Data.diagram_info2_1_2" :key="index">
                <h4 @click="openLink(row.source_url)">
                  <span class="num">{{ index + 1 }}.</span>
                  <div class="title">
                    <span class="mg" v-if="row.emotion < 50">敏感</span>
                    <span class="fmg" v-else>非敏感</span>
                    {{ row.title }}
                  </div>
                </h4>
                <p class="fbsj">
                  <span>发布时间：{{ row.published }}</span>
                  <span>来源：{{ row.category === '微信' ? row.author : row.source_name }}</span>
                </p>
              </li>
            </ul>
          </div>
          <div class="hr-10"></div>
        </div>
      </div>
      <div v-if="activeIndex == 'jczl'" class="jczl_cont">
        <div class="h5_card">
          <p>{{ h5Data['diagram_info2_2_header'] }}</p>
          <!-- 情感声量走势 -->
          <div class="h5_card mar-t-15">
            <h2 class="tit"><span>情感声量走势</span></h2>
            <div class="map" id="emotiontrend">
            </div>
          </div>
          <!--@end 情感声量走势 -->
          <!-- 敏感信息占比 -->
          <div class="ov mar-t-10 h5_card">
            <h2 class="tit"><span>敏感信息占比</span></h2>
            <div class="map" id="sensitive">
            </div>
          </div>
          <!--@end 敏感信息占比 -->
          <div class="mar-t-10">
            <h3>敏感高峰日焦点信息</h3>
            <ul class="zdxxzy_cont">
              <li v-for="(row, index) in h5Data.diagram_info2_2_3" :key="index">
                <h4 @click="openLink(row.source_url)">
                  <span class="num">{{ index + 1 }}.</span>
                  <div class="title">
                    <span class="mg" v-if="row.emotion < 50">敏感</span>
                    <span class="fmg" v-else>非敏感</span>
                    {{ row.title }}
                  </div>
                </h4>
                <p class="fbsj">
                  <span>发布时间：{{ row.published }}</span>
                  <span>来源：{{ row.category === '微信' ? row.author : row.source_name }}</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="activeIndex == 'zdxxzy'" class="zdxxzy_cont">
        <div :class="k === 0 ? 'h5_card' : 'h5_card mar-t-10'" v-for="(item, k) in h5Data['important']" :key="k">
          <p v-if="k === 0">{{ h5Data['diagram_info2_3_header'] }}</p>
          <!-- 网媒焦点 -->
          <div>
            <h3>（{{ indexMap[k] }}）{{ item.name }}</h3>
            <ul class="zdxxzy_cont">
              <li v-for="(row, sindex) in item.data" :key="sindex">
                <h4 @click="openLink(row.source_url)">
                  <span class="num">{{ sindex + 1 }}.</span>
                  <div class="title">
                    <span class="mg" v-if="row.emotion < 50">敏感</span>
                    <span class="fmg" v-else>非敏感</span>
                    {{ row.title }}
                  </div>
                </h4>
                <p class="fbsj">
                  <span>发布时间：{{ row.published }}</span>
                  <span>媒体：{{ row.category }}</span>
                  <span>来源：{{ row.category === '微信' ? row.author : row.source_name }}</span>
                </p>
              </li>
            </ul>
          </div>
          <!--@end 网媒焦点 -->
        </div>
      </div>
      <div v-if="activeIndex == 'xxylb'" class="zdxxzy_cont">
        <div class="h5_card" v-for="(item, index) in h5Data['message_list']" :key="index" style="margin-bottom: 10px;">
          <h4>
            <span class="num">{{ index + 1 }}.</span>
            <div class="title">
              <span class="mg" v-if="item.emotion == 'negative'">敏感</span>
              <span class="fmg" v-else>非敏感</span>
              {{ item.title }}
            </div>
          </h4>
          <p class="fbsj">
            <span>发布时间：{{ item.published }}</span>
            <span>媒体：{{ item.category }}</span>
          </p>
          <p class="fbsj"><span>来源：{{ item.category === '微信' ? item.author : item.source_name }}</span></p>
          <p class="summary" v-if="item.description && item.category !== '微博'">摘要：{{ item.description }}</p>
          <a :href="item.source_url" target="_blank" class="more">查看原文 &gt;&gt;</a>
        </div>
      </div>
      <footer class="h5_footer" v-if="version === 'common'">数知舆情管理系统 版权所有</footer>
    </div>
  </el-container>
</template>
<script>
  import {globalGetEcharts, globalCreateChart, globalMediaColor} from "@/utils/helpers";
  import {detail} from "@/api/autobrief";
  export default {
    name: "component-brief-advanced",
    components: {},
    data() {
      return {
        activeIndex: "gs",
        indexMap: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'],
        h5Data: {},
        list: {data: [], hasMore: true, page: 0, loading: false},
        identical: false,
        voicetrendChart: null,
        version: process.env.VUE_APP_VERSION
      }
    },
    mounted() {
      this.loadData()
    },
    watch: {
      activeIndex: function (index) {
        if (index === 'zdxxzy') {
          if (this.voicetrendChart) {
            this.voicetrendChart.dispose()
          }
        }
      }
    },
    methods: {
      loadData() {
        const {id} = this.$route.query;
        if (!id) {
          this.$message.error('不存在该简报');
          return false;
        }

        detail({id}).then(res => {
          const {state, data} = res.data;
          if (state) {
            this.h5Data = data.result;
            this.handleData()
          } else {
            this.$message.error(res.data.error)
          }
        }).catch(err => {
          window.console.error(err)
        })
      },
      handleData() {
        var data = this.h5Data;
        let important = []
        if (_.size(data['diagram_info2_3_1']) >= 1) {
          important.push({name: "网媒焦点", data: data['diagram_info2_3_1']})
        }
        if (_.size(data['diagram_info2_3_2']) >= 1) {
          important.push({name: "微博焦点", data: data['diagram_info2_3_2']})
        }
        if (_.size(data['diagram_info2_3_3']) >= 1) {
          important.push({name: "微信焦点", data: data['diagram_info2_3_3']})
        }
        data['important'] = important
        this.h5Data = data
        this.$nextTick(() => {
          this.voicetrend()
        })
      },
      voicetrend() {
        var _this = this
        let element = window.document.getElementById('voicetrend')
        let data = _this.h5Data.diagram_info2_1_1.data.map(function (item, index) {
          return {
            data: item, type: 'line', name: _this.h5Data.diagram_info2_1_1.legend[index], smooth: true, areaStyle: {
              normal: {}
            }
          }
        })
        let option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            top: "45px",
            data: _this.h5Data.diagram_info2_1_1.legend
          },
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100
            }
          ],
          grid: {
            left: '38px',
            right: '62px',
            top: '80px',
            bottom: '40px',
            containLabel: true
          },
          toolbox: {
            show: true,
            feature: {},
            right: "3%",
            top: "10px"
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: _this.h5Data.diagram_info2_1_1.keys
          },
          yAxis: {
            type: 'value'
          },
          color: ['#4fcfb0', '#238fff', '#ff4f65', '#f98d3b', '#febd2e', '#7bbdff', '#2ecd42', '#7a5f08', '#95bedd', '#8e8e8e'],
          series: data
        }
        var myChart = globalCreateChart(element, option)
        this.voicetrendChart = myChart
//        this.$nextTick(() =>{
//          var parent = window.document.getElementById("voicetrend")
//          var child = parent.getElementsByTagName("div")
//
//          window.jQuery(child).css({display: "block"})
//        })
      },
      emotiontrend() {
        var _this = this
        var element = window.document.getElementById('emotiontrend')
        var echarts = globalGetEcharts()
        _.each(this.h5Data.diagram_info2_2_1.legend, (item, index) => {
          if (item === '敏感') {
            _this.h5Data.diagram_info2_2_1.negative = _this.h5Data.diagram_info2_2_1.data[index]
          } else if (item === '非敏感') {
            _this.h5Data.diagram_info2_2_1.positive = _this.h5Data.diagram_info2_2_1.data[index]
          }
        })
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          legend: {
            data: ['敏感', '非敏感'],
            top: "45px",
          },
          toolbox: {
            feature: {},
            right: "3%",
            top: "10px"
          },
          grid: {
            left: '38px',
            right: '62px',
            top: '80px',
            bottom: '40px',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: _this.h5Data.diagram_info2_2_1.key
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100
            }
          ],
          color: ['#ff4f65', '#238fff'],
          series: [
            {
              name: '敏感',
              type: 'line',
//             stack: '总量',
              data: _this.h5Data.diagram_info2_2_1.negative,
              smooth: true,
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#ff4f65'
                  }, {
                    offset: 1,
                    color: '#fff'
                  }])
                }
              },
            },
            {
              name: '非敏感',
              type: 'line',
//             stack: '总量',
              data: _this.h5Data.diagram_info2_2_1.positive,
              smooth: true,
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#238fff'
                  }, {
                    offset: 1,
                    color: '#fff'
                  }])
                }
              },
            }
          ]
        }
        var myChart = globalCreateChart(element, option)
      },
      sensitive() {
        var _this = this
        let element = window.document.getElementById('sensitive')
        let total = _this.h5Data.diagram_info2_2_2.neg_num + _this.h5Data.diagram_info2_2_2.pos_num
        let negRate = (_this.h5Data.diagram_info2_2_2.neg_num / total * 100).toFixed(0)
        let posRate = (_this.h5Data.diagram_info2_2_2.pos_num / total * 100).toFixed(0)
        let option = {
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b}: ({d}%)"
          },
          toolbox: {
            show: true,
            feature: {},
            right: "3%",
            top: "10px"
          },
          legend: {
            // orient: 'vertical',
            // x: 'left',
            data: ['敏感', '非敏感'],
            top: "38px",
            formatter: function (name) {
              return name == "敏感" ? "敏感 " + negRate + "%" : "非敏感 " + posRate + "%"
            }
          },
          color: ['#fd5066', '#009df6'],
          series: [
            {
              name: '情感分布',
              type: 'pie',
              center: ['50%', '60%'],
              radius: ['45%', '60%'],
              avoidLabelOverlap: false,
              label: {
                normal: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: '30',
                    fontWeight: 'bold'
                  }
                }
              },
              labelLine: {
                normal: {
                  show: false
                }
              },
              data: [{name: "敏感", value: _this.h5Data.diagram_info2_2_2.neg_num}, {
                name: "非敏感",
                value: _this.h5Data.diagram_info2_2_2.pos_num
              }]
            }
          ]
        }
        var myChart = globalCreateChart(element, option)
      },
      source() {
        var data = this.h5Data.diagram_info2_4_1
        var kMaps = {}
        var total = 0
        var keys = []
        data.map(function (item) {
          total += item.count
        })
        data = data.map(function (item) {
          var rate = (item.count / total) * 100
          rate = rate.toFixed(2).toString()
          kMaps[item.name] = rate + "% "
          keys.push(item.name)
          item.itemStyle = {color: globalMediaColor(item.name)}
          item.value = item.count
          return item
        })
        var option = {
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          legend: {
            orient: 'vertical',
            left: '10px',
            bottom: "20px",
            data: keys,
            icon: "circle",
            itemWidth:10,
            itemHeight:10,
            itemGap:5,
            formatter: function (name) {
              return name + " " + kMaps[name]
            }
          },
          series: [
            {
              name: '来源分布',
              type: 'pie',
              radius: ['45%', '60%'],
              center: ['50%', '50%'],
              data: data,
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                },
                normal: {
                  label: {
                    show: false
                  },
                  labelLine: {
                    show: false
                  }
                }
              }
            }
          ]
        };
        this.$nextTick(function () {
          var element = window.document.getElementById('mediaSourceMap');
          if (!element) return false
          var myChart = globalCreateChart(element, option)
        })
      },
      negativeSource() {
        var data = this.h5Data.diagram_info2_4_5
        var kMaps = {}
        var total = 0
        var keys = []
        data.map(function (item) {
          total += item.count
        })
        data = data.map(function (item) {
          var rate = (item.count / total) * 100
          rate = rate.toFixed(2).toString()
          kMaps[item.name] = rate + "% "
          keys.push(item.name)
          item.itemStyle = {color: globalMediaColor(item.name)}
          item.value = item.count
          return item
        })
        var option = {
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          legend: {
            orient: 'vertical',
            left: '10px',
            bottom: "20px",
            data: keys,
            icon: "circle",
            itemWidth:10,
            itemHeight:10,
            itemGap:5,
            formatter: function (name) {
              return name + " " + kMaps[name]
            }
          },
          series: [
            {
              name: '来源分布',
              type: 'pie',
              radius: ['45%', '60%'],
              center: ['50%', '50%'],
              data: data,
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                },
                normal: {
                  label: {
                    show: false
                  },
                  labelLine: {
                    show: false
                  }
                }
              }
            }
          ]
        };
        this.$nextTick(function () {
          var element = window.document.getElementById('negativeSourceMap');
          if (!element) return false
          var myChart = globalCreateChart(element, option)
        })
      },
      handleClick(index) {
        this.activeIndex = index
        let indexMap = ["gs", "jczl", "zdxxzy", "xxylb"]
        _(indexMap).forEach((v, k) => {
          window.jQuery(".h5_nav a").removeClass("active")
          if (v == index) {
            window.jQuery(".h5_nav a:eq(" + k + ")").addClass("active")
            return false
          }
        })
        switch (index) {
          case "jczl":
            this.$nextTick(() => {
              this.emotiontrend()
              this.sensitive()
            })
            break
          case "zdxxzy":
            break;
          case "xxylb":
            break
          case "gs":
            this.$nextTick(() => {
              this.voicetrend()
            })
            break
        }
      },
      openLink(link) {
        window.open(link, '_blank')
      }
    }
  }
</script>
<style scoped>
.map{height: 300px;}
.h5_header{background-image: linear-gradient(90deg, #009ef6 0%, #5060d6 100%);width:100%;text-align: center;overflow: hidden;color:#fff;padding:10px 0;}
.h5_header h1{font-size:15px;line-height:40px;color:#fff;}
.h5_header p{line-height:20px;font-size:12px;color:#fff;}
.h5_nav{margin:15px 15px 0;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;}
.h5_nav a{height:30px;line-height:30px;background:#e5e5e5;font-size:12px;border-radius:15px;color:rgba(0,0,0,.5);display: inline-block;padding:0 15px;cursor: pointer;}
.h5_nav a.active{color:#fff;background-image: linear-gradient(90deg, #009ef6 0%, #5060d6 100%);}
.gs_cont, .jczl_cont, .zdxxzy_cont {padding:15px;}
.h5_card{background-color: #ffffff;box-shadow:0px 2px 8px 0px rgba(194,194,194,0.2);border-radius:3px;padding: 15px;}
.h5_card h3,.h5_card p{font-size:12px;line-height:24px;color:rgba(1,1,1,.8)}
.h5_card h3,.tszl_cont dt{font-weight:600;}
.h5_card p{text-indent: 2em;}
.h5_card .tit {border-left:2px solid #555DFE;padding-left:15px;font-weight:600;font-size:14px;line-height:14px;color:#000;}
.h5_card .tit .cor_f00{color:red;}
/*重点信息摘要*/
.zdxxzy_cont p.zyxx{margin:0 15px;font-size:12px;line-height:30px;color:rgb(0,0,0);}
.zdxxzy_cont h4{font-size:12px;line-height:24px;color:rgb(0,0,0);font-weight:600;max-height:120px;display: -webkit-box !important;overflow: hidden;text-overflow: ellipsis;word-break: break-all;-webkit-box-orient:vertical;-webkit-line-clamp:2;}
.zdxxzy_cont h4 .mg,.zdxxzy_cont h4 .fmg{background: #f00;border-radius:3px;font-size:12px;padding:5px 10px;color: #fff;margin:0 5px;line-height:22px;}
.zdxxzy_cont h4 .fmg{background:#555DFE;}
.zdxxzy_cont .cor_006{color:#0068b7;}
.zdxxzy_cont .fbsj{line-height:30px;color:rgba(0,0,0,.8);font-size:12px;text-indent:0;}
.zdxxzy_cont .fbsj span{padding-right:10px;}
.zdxxzy_cont .summary{background:rgba(191,191,191,.1);padding:15px;font-size:12px;line-height:18px;color:rgba(0,0,0,.6);text-indent:0;}
.zdxxzy_cont a{text-align: center;font-size:12px;line-height:30px;color:#000;width: 100%;display: block;}
.zdxxzy_cont .xh{font-size:12px;line-height:30px;padding: 10px 0 0;}
.h5_footer{text-align: center;font-size:12px;line-height:60px;color:rgba(0,0,0,.26);}
.w-500{width: 100%;}
@media screen and (min-width:500px) {
  .w-500{width:500px;margin:0 auto;}
}
.button_group >>> .el-loading-mask{border-radius: 25px;}
.echarts_empty{height: 300px;}
a.more{text-align: center;font-size:14px;line-height:50px;color:#000;width: 100%;display: block;}
.mar-t-10 {margin-top: 10px;}
.hr-10 {height: 10px;overflow: hidden;}
ul.zdxxzy_cont li:not(:last-child) {border-bottom: 1px solid rgba(0, 0, 0, .1);padding: 10px 0;}
ul.zdxxzy_cont li:last-child {padding: 10px 0 0;}
</style>
